/*:root {
  --space: 1.5em 0;
  --space: 2em 0;
}*/

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.Site-content {
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}

/*.Site-content:after {
  content: '\00a0';
  display: block;
  margin-top: var(--space);
  height: 0;
  visibility: hidden;
}*/
