#sigContainer {
	border-radius: 10px;
	margin-bottom: 10px;
	background-color: rgb(250, 250, 250);
	box-shadow: 2px 2px 8px rgb(167, 167, 167);
}

#previous-signature {
	width: 400px;
	height: 170px;
}

.canvas {
	height: 300px;
	width: 750px;
}


@media only screen and (min-width : 320px) and (max-width : 479px) {
	.canvas {
		height: 250px;
		width: 310px;
	}
	#previous-signature {
		width: 300px;
	}
}

@media only screen and (min-width : 480px) and (max-width : 666px) {
	.canvas {
		height: 250px;
		width: 470px;
	}
	#previous-signature {
		width: 350px;
	}
}

@media only screen and (min-width : 667px) and (max-width : 991px){
	.canvas {
		height: 300px;
		width: 600px;
	}
	#previous-signature {
		width: 450px;
	}
}

@media only screen and (min-width : 992px) and (max-width : 1199px){
	.canvas {
		height: 300px;
		width: 750px;
	}
}