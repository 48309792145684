html {
  height: 100%;
}
#videos {
  position: relative;
  width: 70%;
  height: 85vh;
  float: left;
  background-color: #5b5b5b66;
}

#subscriber {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

#toggle-video-button {
  z-index: 100;
}
#toggle-audio-button {
  z-index: 100;
}

#video-buttons {
  width: 10%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 300;
  z-index: 10;
}

/* #toolbar {
  width: 4%;
  height: 100%;
  position: absolute;
  right: 0px;
  background-color: #0f192c;
} */

#publisher {
  position: absolute;
  width: 20em;
  height: 13em;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  border: 3px solid white;
  border-radius: 3px;
}
#history {
  width: 100%;
  overflow-y: auto;
  padding: 5px;
  margin: 0;
  max-height: 30rem;
}

#textchat2 {
  position: relative;
  right: 0;
  height: 33rem;
  background-color: #192b4a9a;
  z-index: 100;
  border-radius: 0 10px 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  border-right: 1px solid white;
}

#form {
  right: 4%;
  width: 29.33%;
  height: 100%;
  background-color: white;
  position: absolute;
}

#main-container-patient {
  padding: 0 10rem;
}

#doctor-notes:hover {
  background-color: #05080e;
}

#chat:hover {
  background-color: #05080e;
}
#prescription-button:hover {
  background-color: #05080e;
}
#general-sicknote:hover {
  background-color: #05080e;
}
#school-sicknote:hover {
  background-color: #05080e;
}

#patient-info-button:hover {
  background-color: #05080e;
}
.clicked {
  background-color: #de4816;
  border: 0px solid black;
}
.not-clicked {
  background-color: #000;
  border: 0px solid black;
}
.not-clicked:hover {
  background-color: #383838;
}
input#msgTxt {
  height: 2.5rem;
  bottom: 0;
  width: 95%;
  background-color: #ffffff;
  border: 0;
  border-radius: 20px;
  padding: 15px;
}
input#msgTxt:focus {
  outline: none;
}

#history .mine {
  overflow: hidden;
  color: #ffffff;
  float: right;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  right: 0;
  margin: 1.5px;
}

#history .theirs {
  overflow: hidden;
  color: #ffffff;
  float: left;
  clear: both;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  margin: 1.5px;
}

#timer-wrapper {
  position: relative;
  z-index: 10;
  float: right;
  width: 150px;
  border-radius: 15px;
  background-color: #192b4a;
  margin: 15px;
}
.timer-wrapper-warning {
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  0% {
    background-color: #192b4a;
  }
  50% {
    background-color: rgb(248, 48, 48);
  }
  100% {
    background-color: #192b4a;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 55px;
}
.lds-ellipsis div {
  position: absolute;
  top: 20px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #366ac4;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  #main-container-patient {
    padding: 0;
  }

  #history .mine {
    max-width: 15em;
  }

  #history .theirs {
    max-width: 15em;
  }

  #publisher {
    width: 12em;
    height: 9em;
  }

  #timer-wrapper {
    position: absolute;
    bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #main-container-patient {
    padding: 0;
  }
  #publisher {
    width: 12em;
    height: 9em;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1420px) {
  #main-container-patient {
    padding: 0 6rem;
  }
}
