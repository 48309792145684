.header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "logoArea greetingArea menuArea";
    margin-bottom: 20px;
    border: 1px solid black;
  }

  .logo {
      grid-area: logoArea;
      grid-column: 1/2;
  }

  .message {
    grid-area: greetingArea;
    grid-column: 2/3;

    border: 1px solid black;

}

  .menu {
    grid-area: menuArea;
    grid-column: 3/4;
    border: 1px solid black;

  }
