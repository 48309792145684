.mainContainer {
  padding: 10%;
  width: 100%;
}

.formStyle {
  width: 100%;
}

/* not doing anything right now */
.textAlign {
  align-items: center;
}

.saveButton {
  width: 100%;
}
