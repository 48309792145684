html {
  height: 100%;
}
#videos {
  position: relative;
  width: 70%;
  height: 85vh;
  float: left;
  background-color: #5b5b5b66;
}

#subscriber {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
#subscriber2 {
  position: absolute;
  width: 12em;
  height: 9em;
  top: 10px;
  right: 10px;
  z-index: 1000;
  border: 3px solid white;
  border-radius: 3px;
}
#toggle-video-button {
  z-index: 100;
}
#toggle-audio-button {
  z-index: 100;
}
#video-buttons {
  width: 10%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 300;
  z-index: 10;
}
#toolbar {
  width: 100%;
  height: 100%;
  position: relative;
  right: 0px;
  background-color: #0f192c;
}
#publisher {
  position: absolute;
  width: 20em;
  height: 13em;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  border: 3px solid white;
  border-radius: 3px;
}
#history {
  width: 100%;
  overflow-y: auto;
  padding: 5px;
  margin: 0;
  max-height: 30rem;
}

#textchatDoctor {
  /* position: relative; */
  /* right: 0; */
  width: 29.33%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10;
}

#textchat1 {
  position: relative;
  right: 0;
  width: 100%;
  height: 33rem;
  background-color: #192b4a9a;
  z-index: 10;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  border-right: 1px solid white;
}

#form {
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 100;
}

#form-scroll {
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 100;
  overflow-y: auto;
}

.chat-name-label {
  font-size: 0.7rem;
  color: rgb(128, 157, 253);
  margin: 0;
}

#history .my-timestamp {
  font-size: 0.7rem;
  margin: 0;
  text-align: right;
  color: #dbdbdb;
}

#history .their-timestamp {
  font-size: 0.7rem;
  margin: 0;
  color: #dbdbdb;
}

.mine .text-bubble {
  max-width: 15em;
  background-color: #0269ca;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  padding: 6px;
  border-radius: 5px;
}

.theirs .text-bubble {
  max-width: 15em;
  background-color: #0028aa;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  padding: 6px;
  border-radius: 5px;
}

.chat-text {
  margin: 0;
}

#main-container-doctor {
  padding: 0 10rem;
}

#doctor-notes:hover {
  background-color: #05080e;
}

#videochat:hover {
  background-color: #05080e;
}
#prescription-button:hover {
  background-color: #05080e;
}
#general-sicknote:hover {
  background-color: #05080e;
}
#school-sicknote:hover {
  background-color: #05080e;
}

#patient-info-button:hover {
  background-color: #05080e;
}

#patient-apt-history:hover {
  background-color: #05080e;
}

.clicked {
  background-color: #de4816;
  border: 0px solid black;
}
.not-clicked {
  background-color: #000;
  border: 0px solid black;
}
.not-clicked:hover {
  background-color: #383838;
}
input#msgTxt {
  height: 2.5rem;
  bottom: 0;
  width: 95%;
  background-color: #ffffff;
  border: 0;
  border-radius: 20px;
  padding: 0 10px;
}

input#msgTxt:focus {
  outline: none;
}

#history .mine {
  color: #ffffff;
  float: right;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  right: 0;
  margin: 1.5px;
}

#history .theirs {
  color: #ffffff;
  float: left;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  margin: 1.5px;
}

#timer-wrapper {
  position: relative;
  z-index: 10;
  float: right;
  width: 150px;
  border-radius: 15px;
  background-color: #192b4a;
  margin: 15px;
}
.timer-wrapper-warning {
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  0% {
    background-color: #192b4a;
  }
  50% {
    background-color: rgb(248, 48, 48);
  }
  100% {
    background-color: #192b4a;
  }
}

#patient-left-wrapper {
  position: relative;
  top: -50%;
  left: 45%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.664);
  padding: 10px;
  width: fit-content;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  #main-container-doctor {
    padding: 0;
  }

  #history .mine {
    max-width: 15em;
  }

  #history .theirs {
    max-width: 15em;
  }

  #publisher {
    width: 12em;
    height: 9em;
  }

  #timer-wrapper {
    position: absolute;
    bottom: 0;
  }

  #patient-left-wrapper {
    left: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #main-container-doctor {
    padding: 0;
  }
  #publisher {
    width: 12em;
    height: 9em;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1420px) {
  #main-container-doctor {
    padding: 0 6rem;
  }
}
