.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 55px;
}
.lds-ellipsis div {
  position: absolute;
  top: 20px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #366ac4;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
.mainContainer {
  padding: 10%;
  width: 100%;
}

.formStyle {
  width: 100%;
}

/* not doing anything right now */
.textAlign {
  align-items: center;
}

.saveButton {
  width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 10;
}
#sigContainer {
	border-radius: 10px;
	margin-bottom: 10px;
	background-color: rgb(250, 250, 250);
	box-shadow: 2px 2px 8px rgb(167, 167, 167);
}

#previous-signature {
	width: 400px;
	height: 170px;
}

.canvas {
	height: 300px;
	width: 750px;
}


@media only screen and (min-width : 320px) and (max-width : 479px) {
	.canvas {
		height: 250px;
		width: 310px;
	}
	#previous-signature {
		width: 300px;
	}
}

@media only screen and (min-width : 480px) and (max-width : 666px) {
	.canvas {
		height: 250px;
		width: 470px;
	}
	#previous-signature {
		width: 350px;
	}
}

@media only screen and (min-width : 667px) and (max-width : 991px){
	.canvas {
		height: 300px;
		width: 600px;
	}
	#previous-signature {
		width: 450px;
	}
}

@media only screen and (min-width : 992px) and (max-width : 1199px){
	.canvas {
		height: 300px;
		width: 750px;
	}
}
html {
  height: 100%;
}
#videos {
  position: relative;
  width: 70%;
  height: 85vh;
  float: left;
  background-color: #5b5b5b66;
}

#subscriber {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

#toggle-video-button {
  z-index: 100;
}
#toggle-audio-button {
  z-index: 100;
}

#video-buttons {
  width: 10%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 300;
  z-index: 10;
}

/* #toolbar {
  width: 4%;
  height: 100%;
  position: absolute;
  right: 0px;
  background-color: #0f192c;
} */

#publisher {
  position: absolute;
  width: 20em;
  height: 13em;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  border: 3px solid white;
  border-radius: 3px;
}
#history {
  width: 100%;
  overflow-y: auto;
  padding: 5px;
  margin: 0;
  max-height: 30rem;
}

#textchat2 {
  position: relative;
  right: 0;
  height: 33rem;
  background-color: #192b4a9a;
  z-index: 100;
  border-radius: 0 10px 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  border-right: 1px solid white;
}

#form {
  right: 4%;
  width: 29.33%;
  height: 100%;
  background-color: white;
  position: absolute;
}

#main-container-patient {
  padding: 0 10rem;
}

#doctor-notes:hover {
  background-color: #05080e;
}

#chat:hover {
  background-color: #05080e;
}
#prescription-button:hover {
  background-color: #05080e;
}
#general-sicknote:hover {
  background-color: #05080e;
}
#school-sicknote:hover {
  background-color: #05080e;
}

#patient-info-button:hover {
  background-color: #05080e;
}
.clicked {
  background-color: #de4816;
  border: 0px solid black;
}
.not-clicked {
  background-color: #000;
  border: 0px solid black;
}
.not-clicked:hover {
  background-color: #383838;
}
input#msgTxt {
  height: 2.5rem;
  bottom: 0;
  width: 95%;
  background-color: #ffffff;
  border: 0;
  border-radius: 20px;
  padding: 15px;
}
input#msgTxt:focus {
  outline: none;
}

#history .mine {
  overflow: hidden;
  color: #ffffff;
  float: right;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  right: 0;
  margin: 1.5px;
}

#history .theirs {
  overflow: hidden;
  color: #ffffff;
  float: left;
  clear: both;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  margin: 1.5px;
}

#timer-wrapper {
  position: relative;
  z-index: 10;
  float: right;
  width: 150px;
  border-radius: 15px;
  background-color: #192b4a;
  margin: 15px;
}
.timer-wrapper-warning {
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes blink {
  0% {
    background-color: #192b4a;
  }
  50% {
    background-color: rgb(248, 48, 48);
  }
  100% {
    background-color: #192b4a;
  }
}
@keyframes blink {
  0% {
    background-color: #192b4a;
  }
  50% {
    background-color: rgb(248, 48, 48);
  }
  100% {
    background-color: #192b4a;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 55px;
}
.lds-ellipsis div {
  position: absolute;
  top: 20px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #366ac4;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  #main-container-patient {
    padding: 0;
  }

  #history .mine {
    max-width: 15em;
  }

  #history .theirs {
    max-width: 15em;
  }

  #publisher {
    width: 12em;
    height: 9em;
  }

  #timer-wrapper {
    position: absolute;
    bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #main-container-patient {
    padding: 0;
  }
  #publisher {
    width: 12em;
    height: 9em;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1420px) {
  #main-container-patient {
    padding: 0 6rem;
  }
}

html {
  height: 100%;
}
#videos {
  position: relative;
  width: 70%;
  height: 85vh;
  float: left;
  background-color: #5b5b5b66;
}

#subscriber {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
#subscriber2 {
  position: absolute;
  width: 12em;
  height: 9em;
  top: 10px;
  right: 10px;
  z-index: 1000;
  border: 3px solid white;
  border-radius: 3px;
}
#toggle-video-button {
  z-index: 100;
}
#toggle-audio-button {
  z-index: 100;
}
#video-buttons {
  width: 10%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 300;
  z-index: 10;
}
#toolbar {
  width: 100%;
  height: 100%;
  position: relative;
  right: 0px;
  background-color: #0f192c;
}
#publisher {
  position: absolute;
  width: 20em;
  height: 13em;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  border: 3px solid white;
  border-radius: 3px;
}
#history {
  width: 100%;
  overflow-y: auto;
  padding: 5px;
  margin: 0;
  max-height: 30rem;
}

#textchatDoctor {
  /* position: relative; */
  /* right: 0; */
  width: 29.33%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10;
}

#textchat1 {
  position: relative;
  right: 0;
  width: 100%;
  height: 33rem;
  background-color: #192b4a9a;
  z-index: 10;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  border-right: 1px solid white;
}

#form {
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 100;
}

#form-scroll {
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 100;
  overflow-y: auto;
}

.chat-name-label {
  font-size: 0.7rem;
  color: rgb(128, 157, 253);
  margin: 0;
}

#history .my-timestamp {
  font-size: 0.7rem;
  margin: 0;
  text-align: right;
  color: #dbdbdb;
}

#history .their-timestamp {
  font-size: 0.7rem;
  margin: 0;
  color: #dbdbdb;
}

.mine .text-bubble {
  max-width: 15em;
  background-color: #0269ca;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  padding: 6px;
  border-radius: 5px;
}

.theirs .text-bubble {
  max-width: 15em;
  background-color: #0028aa;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  padding: 6px;
  border-radius: 5px;
}

.chat-text {
  margin: 0;
}

#main-container-doctor {
  padding: 0 10rem;
}

#doctor-notes:hover {
  background-color: #05080e;
}

#videochat:hover {
  background-color: #05080e;
}
#prescription-button:hover {
  background-color: #05080e;
}
#general-sicknote:hover {
  background-color: #05080e;
}
#school-sicknote:hover {
  background-color: #05080e;
}

#patient-info-button:hover {
  background-color: #05080e;
}

#patient-apt-history:hover {
  background-color: #05080e;
}

.clicked {
  background-color: #de4816;
  border: 0px solid black;
}
.not-clicked {
  background-color: #000;
  border: 0px solid black;
}
.not-clicked:hover {
  background-color: #383838;
}
input#msgTxt {
  height: 2.5rem;
  bottom: 0;
  width: 95%;
  background-color: #ffffff;
  border: 0;
  border-radius: 20px;
  padding: 0 10px;
}

input#msgTxt:focus {
  outline: none;
}

#history .mine {
  color: #ffffff;
  float: right;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  right: 0;
  margin: 1.5px;
}

#history .theirs {
  color: #ffffff;
  float: left;
  clear: both;
  font-size: 14px;
  font-family: roboto;
  font-weight: 400;
  overflow-wrap: break-word;
  margin: 1.5px;
}

#timer-wrapper {
  position: relative;
  z-index: 10;
  float: right;
  width: 150px;
  border-radius: 15px;
  background-color: #192b4a;
  margin: 15px;
}
.timer-wrapper-warning {
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes blink {
  0% {
    background-color: #192b4a;
  }
  50% {
    background-color: rgb(248, 48, 48);
  }
  100% {
    background-color: #192b4a;
  }
}
@keyframes blink {
  0% {
    background-color: #192b4a;
  }
  50% {
    background-color: rgb(248, 48, 48);
  }
  100% {
    background-color: #192b4a;
  }
}

#patient-left-wrapper {
  position: relative;
  top: -50%;
  left: 45%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.664);
  padding: 10px;
  width: -webkit-fit-content;
  width: fit-content;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  #main-container-doctor {
    padding: 0;
  }

  #history .mine {
    max-width: 15em;
  }

  #history .theirs {
    max-width: 15em;
  }

  #publisher {
    width: 12em;
    height: 9em;
  }

  #timer-wrapper {
    position: absolute;
    bottom: 0;
  }

  #patient-left-wrapper {
    left: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #main-container-doctor {
    padding: 0;
  }
  #publisher {
    width: 12em;
    height: 9em;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1420px) {
  #main-container-doctor {
    padding: 0 6rem;
  }
}

/*:root {
  --space: 1.5em 0;
  --space: 2em 0;
}*/

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.Site-content {
  flex: 1 1;
  align-items: center;
  display: flex;
  justify-content: center;
}

/*.Site-content:after {
  content: '\00a0';
  display: block;
  margin-top: var(--space);
  height: 0;
  visibility: hidden;
}*/

body > #root {
  margin-top: 65px;
  height: 100vh;
}

input:focus,
textarea {
  outline: none !important;
}

.header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "logoArea greetingArea menuArea";
    margin-bottom: 20px;
    border: 1px solid black;
  }

  .logo {
      grid-area: logoArea;
      grid-column: 1/2;
  }

  .message {
    grid-area: greetingArea;
    grid-column: 2/3;

    border: 1px solid black;

}

  .menu {
    grid-area: menuArea;
    grid-column: 3/4;
    border: 1px solid black;

  }

.StripeElement--focus {
  padding: 9px !important;
  border: solid 2px #192b4a !important;
}

